import * as yup from 'yup';
import { Division } from './Division';

export interface Users {
  app_metadata: AppMetadataModel;
  created_at?: string;
  roles: RoleModel[];
  email: string;
  email_verified: string;
  family_name: string;
  given_name: string;
  name: string;
  updated_at?: string;
  user_id: string;
  user_metadata: UserMetadataModel;
}

interface AppMetadataModel {
  businesses: CustomerModel[];
  created_by_full_name?: string;
  created_by_user_id?: string;
  is_deleted: boolean;
  modified_by_full_name?: string;
  modified_by_user_id?: string;
}

interface CustomerModel {
  dataAreaId: string;
  erpId: string;
  id: string;
  isActive: string;
  isDeleted: string;
  name: string;
  divisions: Division[];
}

export interface RoleModel {
  description: string;
  id: string;
  name: string;
  permissions: PermissionModel[];
}

export interface PermissionModel {
  description: string;
  permission_name: string;
  resource_server_identifier: string;
  resource_server_name: string;
}

interface UserMetadataModel {
  phone_number: 'string' | null | 'NULL';
  employee_erp_id: 'string' | null | 'NULL';
  is_mdsi_employee: boolean;
  profile_image_url: string;
}

export interface EditUserPayload {
  isMdsiEmployee?: boolean;
  employeeERPId?: string | null;
  email?: string;
  givenName?: string;
  familyName?: string;
  phoneNumber?: string | null;
  divisionIdsToAssign?: string[];
  roleIdsToAssign?: string[];
  roleIdsToUnassign?: string[];
  divisionIdsToUnassign?: string[];
}
export interface EditProfile {
  email?: string;
  givenName?: string;
  familyName?: string;
  phoneNumber?: string | null;
  nickname?: string;
}

export interface UserParams {
  businessIds?: string;
  divisionIds?: string;
  nameContains?: string;
  emailContains?: string;
  includeDeletedData?: boolean;
  returnDeletedUsersOnly?: boolean;
  orderByField?: string;
  orderByDirection?: string;
  pageNumber: number;
  take: number;
}

export interface CreateUserPayload {
  isMdsiEmployee?: boolean;
  email: string;
  givenName: string;
  familyName: string;
  divisionIdsToAssign: string[];
  roleIdsToAssign: string[];
  employeeERPId?: string | null;
  password?: string;
}

export const createUserPayload: yup.SchemaOf<CreateUserPayload> = yup.object({
  isMdsiEmployee: yup.boolean().label('MDSi Employee').required(),
  email: yup.string().email().label('Email').required(),
  givenName: yup.string().label('First Name').required(),
  familyName: yup.string().label('Last Name').required(),
  divisionIdsToAssign: yup.array().label('Divisions to Assign').required(),
  roleIdsToAssign: yup.array().label('Roles to Assign').required(),
  employeeERPId: yup.string().label('Employee ERP Id').nullable(),
  password: yup.string().label('Password')
});
